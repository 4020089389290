<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        newPath="/perfil-exposicao/new"
        editPath="/perfil-exposicao/"
        :expander="false"
        subtitle="Perfil de Exposição"
        tooltip="Registro de Perfil de Exposição"
        :hasFiltro="false"
        :filtrosExtras="filtrosExtras"
        nomeTelaDoManual="perfil-exposicao-list"
    >
        <template #anotherFilters>
            <FiltroPerfilExposicao @onChangeFiltro="onChangeFiltro" class="lg:w-6" />
        </template>
        <template #columns>
            <Column field="id" :sortable="true" sortField="perfil_exposicao.id" header="Cód." />
            <Column field="nome" :sortable="true" sortField="perfil_exposicao.nome" header="Nome" />
            <Column field="cliente.name" :sortable="true" sortField="customers.name" header="Cliente" />
            <Column field="created_at" :sortable="true" sortField="perfil_exposicao.created_at" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps?.data?.userCreated?.name }} </small>
                </template>
            </Column>
            <Column field="updated_at" :sortable="true" sortField="perfil.updated_at" header="Atualizado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                    <small class="block text-500"> por {{ slotProps?.data?.userCreated?.name }} </small>
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import SesmtService from '../../services/SesmtService';
import { getCurrentCompany } from '../../services/store';
import FiltroPerfilExposicao from './components/FiltroPerfilExposicao';

export default {
    components: {
        FiltroPerfilExposicao
    },
    data() {
        return {
            record: {},
            filtrosExtras: {}
        };
    },
    created() {
        this.service = new SesmtService('/perfil-exposicao');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar Perfil de exposição' : 'Adicionar Perfil de exposição`';
        },
        tenant() {
            return getCurrentCompany();
        }
    },
    watch: {
        tenant() {
            this.load();
        }
    },
    methods: {
        onChangeFiltro(filtrosExtras) {
            this.filtrosExtras = filtrosExtras;
            this.$refs.grid.load();
        }
    }
};
</script>
