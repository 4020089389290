<template>
    <div class="mt-3 mb-3">
        <div class="flex flex-row p-fluid mt-2 md:mt-0 justify-items-between">
            <div class="field w-4">
                <label for="customersId">Cliente</label>
                <MultiSelectCliente
                    id="customersId"
                    v-model="filters.clientesSelecionados"
                    dataKey="id"
                    optionLabel="name"
                    placeholder="Selecione..."
                />
            </div>
        </div>
        <div class="flex flex-row p-fluid mt-2 md:mt-0 justify-items-between">
            <Chips v-model="filters.text" placeholder="Pesquisar" class="w-full" />
            <Button label="Aplicar filtros" icon="pi pi-search" class="p-button p-button-success w-4 ml-2" @click="aplicarFiltros" />
            <Button label="limpar filtros" icon="pi pi-times" class="p-button p-button-primary w-4 ml-2" @click="removerTodosFiltros" />
        </div>
    </div>
</template>

<script>
import MultiSelectCliente from '../../clientes/components/MultiSelectCliente.vue';
export default {
    emits: ['onChangeFiltro'],
    components: {
        MultiSelectCliente
    },
    data() {
        return {
            filtrosExtras: {},
            filters: {}
        };
    },
    watch: {
        'filters.clientesSelecionados'() {
            this.createFiltersWhere();
        },
        'filters.text'() {
            this.createFiltersWhere();
            this.$emit('onChangeFiltro', this.filtrosExtras);
        }
    },
    methods: {
        createFiltersWhere() {
            const where = [
                {
                    prop: 'perfil_exposicao.nome',
                    operator: 'contains',
                    values: this.filters?.text,
                    ilikeAny: true
                }
            ];

            if (this.filters?.clientesSelecionados?.length) {
                where.push({
                    prop: 'customers.id',
                    operator: 'in',
                    values: this.filters.clientesSelecionados.map((p) => p.id)
                });
            }

            this.filtrosExtras = {
                where
            };
        },
        aplicarFiltros() {
            this.$emit('onChangeFiltro', this.filtrosExtras);
        },
        removerTodosFiltros() {
            this.filters = {};
        }
    }
};
</script>
